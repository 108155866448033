import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import mockupImageSrc from "images/tshirt.png"
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg";
import { ContentWithPaddingXl, Container as ContainerBase } from "components/misc/Layouts";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import appleIconImageSrc from "images/apple-icon.png";
import googlePlayIconImageSrc from "images/google-play-icon.png"
import logo from "images/textLogo.png"
import paypal from "images/paypal.png"

import { Expander, ExpanderItem, ThemeProvider, Theme } from '@aws-amplify/ui-react';

const Container = tw(ContainerBase)`bg-gray-900 -mx-6 lg:py-12`
const Content = tw(ContentWithPaddingXl)``
const Row = tw.div`px-8 flex items-center relative z-10 flex-col lg:flex-row text-center lg:text-left justify-center`;

const ColumnContainer = tw.div`max-w-2xl`
const TextContainer = tw(ColumnContainer)``;
const Text = tw(SectionHeading)`text-gray-100 lg:text-left max-w-none text-3xl leading-snug`;
const Subheading = tw(SubheadingBase)`text-blue-500 mb-4 tracking-wider`
const SubheadingLower = tw.div`text-gray-200 mb-4 tracking-wider py-6 lg:py-6`

const LinksContainer = tw.div`mt-8 lg:mt-16 flex flex-col items-center sm:block`
const Link = styled.a`
  ${tw`w-56 p-3 sm:p-4 text-sm sm:text-base font-bold uppercase tracking-wider rounded-full inline-flex justify-center items-center mt-6 first:mt-0 sm:mt-0 sm:ml-8 first:ml-0 bg-gray-100 hocus:bg-gray-300 text-gray-900 hocus:text-gray-900 shadow hover:shadow-lg focus:shadow-outline focus:outline-none transition duration-300`}
  img {
    ${tw`inline-block h-8 mr-3`}
  }
  span {
    ${tw`leading-none inline-block`}
  }
`;

const LinkPro = styled.a`
  ${tw``}
`;

const ImageContainer = tw(ColumnContainer)`mt-0 lg:mt-0 lg:ml-16 flex justify-end`;
const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`
const DecoratorBlob1 = tw(SvgDecoratorBlob1)`absolute bottom-0 left-0 w-80 h-80 transform -translate-x-20 translate-y-32 text-gray-800 opacity-50`
const DecoratorBlob2 = tw(SvgDecoratorBlob1)`absolute top-0 right-0 w-80 h-80 transform  translate-x-20 -translate-y-64 text-gray-800 opacity-50`

const theme: Theme = {
  name: 'expander-theme',
  tokens: {
    components: {
      expander: {
        backgroundColor: { value: '{colors.blue.10}' },
        borderRadius: { value: '{radii.small}' },
        trigger: {
          minHeight: { value: '5rem' },
          justifyContent: { value: 'center' },
          _hover: {
            backgroundColor: { value: '{colors.overlay.20}' },
          },
        },
        content: {
          paddingInlineStart: { value: '{space.xxl}' },
          text: {
            color: { value: '{colors.blue.80}' },
            paddingBlockEnd: { value: '{space.large}' },
          },
          _open: {
            animationDuration: { value: '{time.short}' },
          },
        },
      },
    },
  },
};

export default ({
  subheading = "50% discount starts June 1st",
  text = "Workout in the new Gymstar Tee - £9.99",
  linkPPText = "Buy Now",
  linkPPUrl = "https://apps.apple.com/gb/app/gymstar-fitness-network/id1234015763",
  link1Text = "App Store",
  link1Url = "https://apps.apple.com/gb/app/gymstar-fitness-network/id1234015763",
  link1IconSrc = appleIconImageSrc,
  link2Text = "Google Play",
  link2Url = "https://play.google.com/store/apps/details?id=com.gymstar.app",
  link2IconSrc = googlePlayIconImageSrc,
  pushDownFooter = false,
  imageSrc = mockupImageSrc,
  subheadingLower = "Offer ends 30th June 2023. ",
  link3Url = "/pro",
}) => {
  return (
    <Container css={pushDownFooter && tw`mb-20 lg:mb-24`}>
      <Content>
      <LogoContainer>
      <img src={logo} alt="logo" width={150} />
      </LogoContainer>
        <Row>
        <TextContainer>
            {subheading && <Subheading>{subheading}</Subheading>}
            </TextContainer>
        <ImageContainer>
            <img src={imageSrc} alt="" tw="w-80 mb-8" css={tw`rounded-lg`}/>
        </ImageContainer>
          <TextContainer>
            <Text>{text}</Text>
            <br>
            </br>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
  <input type="hidden" name="cmd" value="_s-xclick" />
  <input type="hidden" name="hosted_button_id" value="7DJPX489VNWPW" />
  <table css={tw`w-1/3 p-4 mx-auto text-center`}>
    <tr>
      <td>
        <input type="hidden" name="on0" value="Size"/>
        <span css={tw`text-white float-left`}>Size</span>
      </td>
      <td css={tw`float-left`}>
        <select name="os0">
          <option value="XS">
            XS
          </option>
          <option value="S">
            S
          </option>
          <option value="M">
            M
          </option>
          <option value="L">
            L
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td>
        <input type="hidden" name="on1" value="Fit"/>
        <span css={tw`text-white float-left`}>Fit</span>
      </td>
      <td css={tw`float-left`}>
        <select name="os1">
          <option value="Muscle Fit">
            Muscle Fit
          </option>
          <option value="Slim Fit">
            Slim Fit
          </option>
          <option value="Regular">
            Regular
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td>
        <input type="hidden" name="on2" value="Type"/>
        <span css={tw`text-white float-left`}>Type</span>
      </td>
      <td css={tw`float-left`}>
        <select name="os2">
          <option value="Men&#39;s">
            Men&#39;s
          </option>
          <option value="Women&#39;s">
            Women&#39;s
          </option>
        </select>
      </td>
    </tr>
  </table>
  <Expander type="multiple" css={tw`text-gray-400 text-center`}>
              <ExpanderItem title="Product details" value="item-1" css={tw`text-center`}>
                <ol> 
                  <li>
                  Muscle fit: 96% Cotton, 4% Elastane</li><li> Regular & slim fit: 100% Cotton</li>
<li>Iron on reverse</li>
<li>Machine wash at max 40°C gentle</li>
<li>Do not bleach. Do not tumble dry. Do not dry clean</li>
                </ol>
              </ExpanderItem>
              </Expander>
  <input type="hidden" name="currency_code" value="GBP" />
  <br></br>
  <br></br>
  <input type="submit" css={tw`bg-white h-12 w-64 rounded-lg font-bold cursor-pointer`} border="0" value="BUY NOW" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
  <SubheadingLower>{subheadingLower}</SubheadingLower>
  <img src={paypal} alt="PayPal - The safer, easier way to pay online!" css={tw`w-1/2 p-2 mx-auto text-center mt-0`} />
</form>
          </TextContainer>
        </Row>
        <DecoratorBlobContainer>
          <DecoratorBlob1/>
          <DecoratorBlob2/>
        </DecoratorBlobContainer>
      </Content>
    </Container>
  );
};
