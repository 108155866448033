import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Blog from "components/blogs/GridWithFeaturedPost.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import {Container} from "components/misc/Layouts";

const HighlightedText = tw.span`text-primary-500`

const SectionContainer = tw(Container)``;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Hero />
      <SectionContainer id="Gyms">
      <FeatureStats/>
      </SectionContainer>
      <SectionContainer id="features">
      <Features 
        heading="How Gymstar can help your Gym"
      />
      </SectionContainer>
      <SectionContainer id="benefits">
      <MainFeature
        heading="Bring your gym together"
      />
      </SectionContainer>
      <SectionContainer id="tools">
      <Testimonial 
        heading="Move faster with your tools in one place"
      />
      </SectionContainer>
      {/*<SectionContainer id="pricing">
      <Pricing
        heading="Grow your gym. Increase activity"
      />
  </SectionContainer>*/}
      <SectionContainer id="social">
      <Blog
        subheading = ""
        heading="Share & inspire your community"
      />
      </SectionContainer> 
      <GetStarted/>
      <Footer />
    </AnimationRevealPage>
  );
}
