import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/tshirt.png";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col lg:flex-row`
const Input = tw.input`border-2 px-5 py-3 rounded focus:outline-none font-medium transition duration-300 hocus:border-primary-500`

const SubmitButton = tw(PrimaryButtonBase)`inline-block lg:ml-6 mt-6 lg:mt-0`

export default ({
  subheading = "Be the first to get it at your Gym",
  heading = <>Select your gym and get <span tw="text-primary-500">50% off</span><wbr/> the Gymstar Tee!</>,
  description = <>Buy the exclusive Gymstar t-shirt for <span tw="text-primary-500">£9.99</span><br/>* Offer ends June 30th 2023.</>,
  submitButtonText = "Buy Now",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{description}</Description>
            <Form action={formAction} method={formMethod}>
              <Input type="email" name="email" placeholder="Your Email Address" />
              <Input type="text" name="gym" placeholder="Select your gym" />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
  <input type="hidden" name="cmd" value="_s-xclick" />
  <input type="hidden" name="hosted_button_id" value="7DJPX489VNWPW" />
  <table>
    <tr>
      <td>
        <input type="hidden" name="on0" value="Size"/>
        Size
      </td>
    </tr>
    <tr>
      <td>
        <select name="os0">
          <option value="XS">
            XS
          </option>
          <option value="S">
            S
          </option>
          <option value="M">
            M
          </option>
          <option value="L">
            L
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td>
        <input type="hidden" name="on1" value="Fit"/>
        Fit
      </td>
    </tr>
    <tr>
      <td>
        <select name="os1">
          <option value="Muscle Fit">
            Muscle Fit
          </option>
          <option value="Slim Fit">
            Slim Fit
          </option>
          <option value="Regular">
            Regular
          </option>
        </select>
      </td>
    </tr>
    <tr>
      <td>
        <input type="hidden" name="on2" value="Type"/>
        Type
      </td>
    </tr>
    <tr>
      <td>
        <select name="os2">
          <option value="Men&#39;s">
            Men&#39;s
          </option>
          <option value="Women&#39;s">
            Women&#39;s
          </option>
        </select>
      </td>
    </tr>
  </table>
  <input type="hidden" name="currency_code" value="GBP" />
  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
</form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
